.maptable {
    border-collapse: collapse;
    font-size: 0.85rem;
}

.maptable th, .maptable td, .maptable tr {
    border-bottom: 1px solid lightgrey;
    padding: 6px;
}

.gm-style-mtc button {
    height:30px!important;
    font-size:12px!important;
}


.qtable .k-grid-table {
    font-size: 0.8rem;
  }
  
  
  .qtable .k-column-title {
    font-size: 0.8rem;
  }
  
   
  .qtable .k-grid-footer td{
    font-size: 0.8rem;
  }
  
  .qtable .k-header span {
    white-space: normal;
  }

  .qtable td{padding-top: 2px;padding-bottom: 2px;}