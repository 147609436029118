/* html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-family: "Bitter", sans-serif;
  zoom: 80%;
} */


.orgchart-container {
  border: none;
  height: 980px !important;
  width: 100%;
  background: transparent;
  margin: 0 auto;

}
.sekure-org-chart {
  background-image: none !important;
  margin: 0 auto;

}
.orgchart > ul > li > ul li::before {
  border-top: 2px solid #939597 !important;
}

.orgchart > ul > li > ul li > .oc-node::before,
.orgchart ul li .oc-node:not(:only-child)::after {
  height: 14px !important;
  background-color: #939597 !important;
}

.orgchart ul li .oc-node:hover,
.orgchart ul li .oc-node.selected {
  background-color: transparent !important;
}

.oc-edge.leftEdge,
.oc-edge.rightEdge,
.oc-edge.topEdge,
.oc-edge.bottomEdge {
  display: none !important;
}

.org-node-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  min-height: 50px;
}

.org-position {
  width: 32px;
  height: 164px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #363945;
}

.org-person {
  width: 128px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid #939597;
  position: relative;
}

.headshot {
  color: #00835d;
  display: flex;
  width: 90px;
  height: 90px;
  margin-bottom: 10px;
  border-left: 0px solid #00835d;
  border-bottom: 0px solid #00835d;
}

.org-name {
  font-size: 16px;
  font-weight: 500;
}

.org-node-children {
  color: #00835d;
  text-decoration: underline;
  cursor: pointer;
}

.open-user-details {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  cursor: pointer;
  background: #00835d;
  border: 2px solid #00835d;

  border-radius: 12px;
}
.open-user-details:hover {
  background: #a3e2b5;
  color: #121e22;
  border: 2px solid #a3e2b5;
}

.contact-link {
  color: #fff;
  text-decoration: none;
}
.contact-link:hover {
  color: #121e22;
}

.org-person img {
  object-fit: fill;
  width: 128px;
  height: 126px;
  position: relative;
  top:2px;

}