body{
  font-family: 'KoPub';
}
.k-card-body {
    padding: 10px 10px;

}

.k-tilelayout-item-header.k-card-header {
font-weight: bolder!important;
}

.bolder{
  font-weight: bolder;
}

  .hovertext:hover {

    cursor: pointer;
    color:#F37321
  }
  
.cardcenter{
    text-align: center;
}  

.center {
  display: flex;
  justify-content: center;
  align-items: center;

}





.fluid {
  max-width: 900px;
  /* margin: 0 auto; */
  display: flex;
  flex-direction: row;
  font-family: Arial;
  line-height: 1.3;
  font-size: 16px;
  padding-left: 5px;
}

.fluid-c {
  width: 1800px;
  /* margin: 0 auto; */
  display: flex;
  flex-direction: row;

}

.fluid-c2 {
  width: 900px;
  /* margin: 0 auto; */
  display: flex;
  flex-direction: row;
  margin-left: 1000px;

}


.fluid__instructions {
  flex: 0 0 auto;
  margin: 0 20px;
}

.fixed__instructions {
  flex: 1;
  margin: 0 20px;
}
  

.fluid-bar {
  max-width: 1200px;
  height: 600px;
 
}

.photo {
  width: 800px;    padding-left: 5px;
}

.desktop{
  padding: 4px!important;
  font-size: smaller;
}

.k-task-content{
  overflow: visible!important;
  color:black;
}

.k-gantt-toolbar{ 
  height: 0!important;
  padding: 0!important;
}

.k-card-title{
  font-size: 1rem;
}


.qcardlayout {
  padding:50px;
}

.k-grid table{
font-size: 1rem;
}
    /* Handle 125% and 150% Windows 10 Font Scaling on 96dpi monitors */
    @media (min-resolution: 120dpi) {
      html {
           font-size: 70%;
      }
   
      .qcardlayout {
        padding:5px;
        margin-top:45px;
      }


      
  }


  .MuiTableCell-root {
    padding: 1px 3px!important;;
  }

  .box {
    border-radius: 0;
    background: white;
    display: grid;
    place-content: center;
    --shadow-color: hsl(220deg 60% 50%);
  }

  @font-face {
    font-family: "KoPub";
    src: url("./font/KoPub Dotum_Pro Light.otf") format("opentype");
    font-display: auto;
    
  }  
  
  .tableleft {
    width: 15%;
    font-size: 1.5rem;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid rgba(243, 115, 33) ;
    padding-left: 25px;
    display: table-cell;

  }

  .tableright {
    width: 85%;
    font-size: 1.2rem;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid rgba(243, 115, 33) ;
    display: table-cell;
    margin: 0!important;

  }

  .rimage img {
    height: 70%; 
    width: 70%;
    object-fit:contain;
  }


  .cost .k-grid-header col:nth-of-type(1){
    width: 18%;
  }
  .cost .k-grid-table col:nth-of-type(1){
    width: 18%
  }
  .cost .k-grid-footer col:nth-of-type(1){
    width: 18%
  }
  
  .cost .k-grid-header col:nth-of-type(5){
    width: 8%;
  }
  .cost .k-grid-table col:nth-of-type(5){
    width: 8%
  }
  .cost .k-grid-footer col:nth-of-type(5){
    width: 8%
  }
  
  .cost .k-grid-header col:nth-of-type(6){
    width: 8%;
  }
  .cost .k-grid-table col:nth-of-type(6){
    width: 8%
  }
  .cost .k-grid-footer col:nth-of-type(6){
    width: 8%
  }
  
  .cost .k-grid-header col:nth-of-type(7){
    width: 14%;
  }
  .cost .k-grid-table col:nth-of-type(7){
    width: 14%
  }
  .cost .k-grid-footer col:nth-of-type(7){
    width: 14%
  }

table.type11 { border-collapse: separate; border-spacing: 1px; text-align: center; line-height: 1.5; margin: 20px 10px;}
table.type11 th { width: 70px; padding: 5px; font-weight: bold; vertical-align: top; color: #fff; background: rgba(243, 115, 33) ;}
table.type11 td { width: 70px; padding: 3px; vertical-align: middle; border-bottom: 1px solid #ccc; background: #eee;}

.quality .k-grid-header th {
  font-size: 1rem;
}

.quality .k-grid-table td {
  font-size: 1rem;
}

table.type12 { border-collapse: separate; border-spacing: 1px; text-align: center; line-height: 1.5; margin: 20px 10px;}
table.type12 th { padding: 10px; font-weight: bold; vertical-align: top; color: #fff; background: rgba(243, 115, 33) ; font-size: 1rem;}
table.type12 td { padding: 5px; vertical-align: middle; border-bottom: 1px solid #ccc; background: #eee;font-size: 1rem;}

.meeting .k-card-title {
  font-size: 1.2rem!important;

}

.meeting .charttitle {
  font-size: 1rem!important;
  margin-top: 15px;

}

.meeting img{
  width: 75%!important;

}

.quill td {
  font-size: 1rem!important;
}

.ment li , p{
  position: relative;
}
.ment img {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 600px; 
  object-fit: contain;
  transform: translateY(100%);
}



.ment2 li , p{
  position: relative;
}
.ment2 img {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 950px; 
  object-fit: contain;
  transform: translateY(100%);
}


table.type12 td img{
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 600px; 
  max-height: 200px; 
  object-fit: contain;
  transform: translateY(100%);

}


.quality .k-grid-header col:nth-of-type(2){
  width: 25%;
}
.quality .k-grid-table col:nth-of-type(2){
  width: 25%
}
.quality .k-grid-footer col:nth-of-type(2){
  width: 25%
}

.quality .k-grid-header col:nth-of-type(3){
  width: 12%;
}
.quality .k-grid-table col:nth-of-type(3){
  width: 12%
}
.quality .k-grid-footer col:nth-of-type(3){
  width: 12%
}

.quality .k-grid-header col:nth-of-type(7){
  width: 7%;
}
.quality .k-grid-table col:nth-of-type(7){
  width: 7%
}
.quality .k-grid-footer col:nth-of-type(7){
  width: 7%
}




table.type13 { border-collapse: separate; border-spacing: 1px; text-align: center; line-height: 1.5; margin: 20px 10px;}
table.type13 th {  padding: 10px; font-weight: bold; vertical-align: top; color: #fff; background: rgba(243, 115, 33) ;}
table.type13 td {  padding: 10px; vertical-align: middle; border-bottom: 1px solid #ccc; background: #eee; text-align: right;}

h3{
  margin: 6px;
  font-weight: 500;

}

.tooltiptable  .k-grid-table td{
  font-size: 0.8rem;
  text-align: center;
}


.tooltiptable  .k-grid-header th { 
  font-size: 0.8rem;
  text-align: center;
  margin: 0 auto;
}


.tooltiptable
  table
    thead 
     tr .k-column-title { display: flex; width: 100%;justify-content: center;}
    
  
.k-checkbox-label{font-size: 0.75rem;}
  
.slider {
  color: #fff;
}

.slider .swiper-container1 {
  width: 100%;
  height: 100%;
}

.slider .swiper-container2 {
  width: 100%;
  height: 100%;
}

.slider__flex {
  display: flex;
  align-items: flex-start;
}

.slider__col {
  display: flex;
  flex-direction: column;
  width: 200px;
}


.swiper-slide-thumb-active {
  opacity: 1!important;
}

.swiper-wrapper{opacity: 1;}

.swiper-slide-visible{ 
  opacity: 0.4;
}
