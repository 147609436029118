.meeting4 .k-grouping-header.k-grouping-header-flex {
  display: none !important;
}

.meeting4 .k-grid-header col:nth-of-type(2) {
  width: 16%;
}
.meeting4 .k-grid-table col:nth-of-type(2) {
  width: 16%;
}
.meeting4 .k-grid-footer col:nth-of-type(2) {
  width: 16%;
}

.meeting4 .k-grid-header col:nth-of-type(6) {
  width: 6%
}
.meeting4 .k-grid-table col:nth-of-type(6) {
  width: 6%;
}
.meeting4 .k-grid-footer col:nth-of-type(6) {
  width: 6%;
}

.meeting4 .k-grid-header col:nth-of-type(8) {
  width: 6%;
}
.meeting4 .k-grid-table col:nth-of-type(8) {
  width: 6%;
}
.meeting4 .k-grid-footer col:nth-of-type(8) {
  width: 6%;
}

.k-grid-header, .k-grouping-header, .k-grid-add-row, .k-grid-footer { 
  display: none;
} 

.money  {
  text-align: right!important;
}
